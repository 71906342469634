export default [

    // USER PROFILE
    {
        name: 'UserProfile',
        path: '/user-profile',
        component: () =>
            import('@/views/UserProfile'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // APPROVAL FLOW
    {
        name: 'ApprovalFlow',
        path: '/approval-flow',
        component: () =>
            import('@/views/ApprovalFlow'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ApprovalFlowLevel',
        path: '/approval-flow-level',
        component: () =>
            import('@/views/ApprovalFlowLevel'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ApprovalFlowTransaction',
        path: '/approval-flow-transaction',
        component: () =>
            import('@/views/ApprovalFlowTransaction'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    // {
    //     name: 'ApprovalFlowCostCenter',
    //     path: '/approval-flow-cost-center',
    //     component: () =>
    //         import('@/views/ApprovalFlowCostCenter'),
    //         meta: {
    //             requireAuth: true,
    //             redirectHomeIfLogged: false
    //         }
    // },
    {
        name: 'PlanningGroupVersion',
        path: '/planning-group-version',
        component: () =>
            import('@/views/PlanningGroupVersion'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'PlanningGroup',
        path: '/planning-group',
        component: () =>
            import('@/views/PlanningGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ApprovalFlowPlanningGroup',
        path: '/approval-flow-planning-group',
        component: () =>
            import('@/views/ApprovalFlowPlanningGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'EventStatusPlanningGroup',
        path: '/event-status-planning-group',
        component: () =>
            import('@/views/EventStatusPlanningGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // ACCOUNT
    {
        name: 'ChartAccountGroup',
        path: '/chart-account-group',
        component: () =>
            import('@/views/ChartAccountGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ChartAccountGroupVersion',
        path: '/chart-account-group-version',
        component: () =>
            import('@/views/ChartAccountGroupVersion'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroup',
        path: '/account-group',
        component: () =>
            import('@/views/AccountGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupCalc',
        path: '/account-group-calc',
        component: () =>
            import('@/views/AccountGroupCalc'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupVersion',
        path: '/account-group-version',
        component: () =>
            import('@/views/AccountGroupVersion'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupReference',
        path: '/account-group-reference',
        component: () =>
            import('@/views/AccountGroupReference'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupSystemId',
        path: '/account-group-system-id',
        component: () =>
            import('@/views/AccountGroupSystemId'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupFilterVersion',
        path: '/account-group-filter-version',
        component: () =>
            import('@/views/AccountGroupFilterVersion'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupFilter',
        path: '/account-group-filter',
        component: () =>
            import('@/views/AccountGroupFilter'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // EVENT
    {
        name: 'Event',
        path: '/event',
        component: () =>
            import('@/views/Event'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'EventConfig',
        path: '/event-config',
        component: () =>
            import('@/views/EventConfig'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'EventConfigAccountGroup',
        path: '/event-config-account-group',
        component: () =>
            import('@/views/EventConfigAccountGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // PRODUCT
    {
        name: 'ProductPurchasePlanning',
        path: '/product-purchase-planning',
        component: () =>
            import('@/views/ProductPurchasePlanning'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'VendorPriceList',
        path: '/vendor-price-list',
        component: () =>
            import('@/views/VendorPriceList'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
   
    // PURCHASE PLANNING
    {
        name: 'PurchasePlanning',
        path: '/purchase-planning',
        component: () =>
            import('@/views/PurchasePlanning'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // PURCHASE STRUCTURE
    {
        name: 'PurchaseChannelType',
        path: '/purchase-channel-type',
        component: () =>
            import('@/views/PurchaseChannelType'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'PurchaseChannel',
        path: '/purchase-channel',
        component: () =>
            import('@/views/PurchaseChannel'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'DetailedPurchasePlanning',
        path: '/detailed-purchase-planning',
        component: () =>
            import('@/views/DetailedPurchasePlanning'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ReportGallery',
        path: '/report-gallery',
        component: () =>
            import('@/views/ReportGallery'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'PurchaseReport',
        path: '/purchase-report',
        component: () =>
            import('@/views/PurchaseReport'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
]